.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 10px;
}

.footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px 10px;
}

