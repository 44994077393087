.countryCode {
  color: var(--intent-color-text-neutral-subtle);
  margin: auto 0;
}

.countrySelectContainer {
  border: var(--intent-border-width-input) solid
    var(--intent-color-input-border-default-resting);
  border-radius: 4px;
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.countrySelectContainer:focus-within {
  outline-offset: var(--base-spacing-2xs);
  outline: var(--intent-color-border-brand-hover) solid
    var(--intent-border-width-focus-ring);
}

.countrySelectContainer > select {
  border: unset !important;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 55px;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.countrySelectContainer > select:focus ~ svg,
.countrySelectContainer > select:hover ~ svg,
.countrySelectContainer > select:active ~ svg {
  fill: var(--intent-color-border-brand-hover) !important;
}

.chevronIcon {
  flex-shrink: 0;
}

.countryFlag {
  margin-left: 10px;
}

.phoneNumberInput {
  border: none;
  outline: none;
}

.phoneNumberInput > div {
  border: none;
  outline: none;
  overflow: hidden;
}

.phoneNumberInput > div:focus-visible:hover {
  border: none;
  outline: none;
}

.phoneNumberInput > div > input {
  border: none;
  outline: none;
}

.phoneNumberInput > div > input:focus-visible {
  border: none;
  box-shadow: none;
  outline: none;
}

.phoneNumberInput > div > input:focus-visible:hover {
  border: none;
  box-shadow: none;
  outline: none;
}
