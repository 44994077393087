.tooltip.reactTooltip {
  display: inline-block;
  position: relative;
}

.tooltip.reactTooltip:hover .tooltipContent {
  opacity: 1;
  visibility: visible;
}

.tooltipContent {
  background: rgba(28, 29, 31, 0.8);
  color: var(--foundation-white);
  text-transform: none;
  letter-spacing: 0;
  font-size: 14px;
  text-align: center;
  display: block;
  white-space: nowrap;
  position: absolute;
  padding: 20px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
  transition: opacity 200ms ease, visibility 200ms ease;
}

.tooltipContent:after {
  content: "";
  background: transparent;
  width: 0;
  height: 0;
  position: absolute;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.9);
  bottom: -5px;
}

.arrowCenter:after {
  left: 50%;
}

.arrowRight:after {
  right: 15px;
}

.tooltipContent:before {
  content: "";
  background: transparent;
  display: block;
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: -10px;
  left: 0px;
}
