.highlightedText {
  background-color: var(--foundation-blue-200);
}

.header {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: var(--foundation-black);
}

.subheader {
  font-size: 14px;
  line-height: 20px;
  color: var(--foundation-black);
  margin-bottom: 48px;
}

.secondaryHeader {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--foundation-blue-600);
}

.legalLink {
  color: var(--foundation-black);
}

.link {
  color: var(--foundation-blue-400);
  text-decoration: none;
}

.link:hover, .link:focus {
  text-decoration: underline;
}
