.FilterableSelectInput {
  border-radius: 4px;
}

.FilterableSelectInput.isOpen {
  box-shadow: 0 2px 4px rgba(28, 29, 31, 0.5);
}

.FilterableSelectInput.isOpen .input-header {
  border-radius: 4px 4px 0 0;
  border-color: #1e98ff;
  border-bottom: none;
}

.FilterableSelectInput.isOpen .arrow-icon {
  transform: rotate(180deg);
  color: #1e98ff;
}

.FilterableSelectInput .input-header {
  width: 100%;
}

.FilterableSelectInput .input-header:focus + .toggle-arrow-container .arrow-icon {
  color: #1e98ff;
}

.FilterableSelectInput .current-selection::placeholder {
  color: #1c1d1f;
}

.FilterableSelectInput .clear-selection-container {
  position: relative;
  width: 100%;
}

.FilterableSelectInput .clear-selection-button {
  position: absolute;
  right: 7px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
}

.FilterableSelectInput .clear-selection-button:focus,
.FilterableSelectInput .clear-selection-button:hover {
  cursor: pointer;
}

.FilterableSelectInput .clear-selection-button:focus .clear-icon,
.FilterableSelectInput .clear-selection-button:hover .clear-icon {
  color: #1e98ff;
}

.FilterableSelectInput .clear-icon {
  color: #848890;
}

.FilterableSelectInput .toggle-arrow-container {
  position: relative;
  width: 100%;
}

.FilterableSelectInput .arrow-icon {
  position: absolute;
  right: 7px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  color: #848890;
  cursor: pointer;
}