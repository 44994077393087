.main {
  align-items: center;
  display: grid;
  margin: 0;
  padding: 0;
  height: 100%;
  text-align: center;
}

.link {
  composes: link from '/src/assets/styles/TextStyles.module.css';
}
