.content {
  composes: content from '/src/assets/styles/Layout.module.css';
  padding: 20px 40px;
  max-width: 816px;
  margin: auto;
}

.cardContent {
  padding: var(--base-spacing-lg);
  border: 1px solid var(--foundation-gray-400);
  border-radius: var(--base-border-radius-lg);
  display: flex;
  gap: var(--base-spacing-md);
}

.bankIconContainer {
  line-height: 0;

  > div {
    padding: var(--base-spacing-2xs);
    background-color: var(--base-color-gray-200);
    border-radius: 5px;
  }
}

.bankInfoContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--base-spacing-lg);
}

.bankNameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bankName {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .bankCountry {
    width: 100%;
    margin-top: var(--base-spacing-2xs);
    font-size: var(--base-font-font-size-sm);
  }

  h2 {
    font-weight: 800;
    line-height: var(--base-font-line-height-md);
    font-size: var(--base-font-font-size-lg);
    margin: 0;
    margin-right: var(--base-spacing-sm);
  }
}

.bankDataContainer {
  display: flex;
  flex-direction: row;
  gap: var(--base-spacing-md);

  > div {
    flex-basis: 50%;
  }
}

.contentLabelContainer {
  line-height: var(--base-font-line-height-md);
}

.contentLabel {
  font-size: var(--base-font-font-size-sm);
  font-weight: bold;
  color: var(--base-color-gray-800);
}

.editButton {
  text-transform: capitalize;
  letter-spacing: var(--base-font-letter-spacing-default);
  font-weight: bold;
  line-height: var(--base-font-line-height-md);
  font-size: var(--base-font-font-size-sm);
}

.pageHeader {
  padding-bottom: var(--base-spacing-md);
  padding-top: var(--base-spacing-md);
}

.pageHeader h1 {
  font-size: var(--base-font-font-size-xl);
  font-weight: bold;
}
