.documentsTable {
  color: var(--foundation-black);
  max-width: 963px;
  width: 100%;
}

.desktopTable {
  visibility: hidden;
  display: none;
}

.mobileTable {
  visibility: visible;
  display: table;
}

.mobileViewHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.mobileViewLabel {
  font-weight: 700;
}

.sortByContainer {
  align-items: center;
  display: flex;
}

.sortByLabel {
  font-size: 14px;
  margin-right: 10px;
}

.documentsPlaceholder {
  border: 1px solid var(--foundation-gray-400);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  width: 100%;
}

.documentsPlaceholder .icon {
  margin-bottom: 4px;
}

.documentsPlaceholder .title {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
}

.documentsPlaceholder .message {
  font-size: 14px;
  line-height: 20px;
}

.documentsTable table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
}

.documentsTable thead, .documentsTable tr {
  border-bottom: 1px solid var(--foundation-gray-400);
}

.documentsTable th {
  color: var(--foundation-gray-700);
  font-weight: 700;
  height: 40px;
  text-transform: uppercase;
  font-size: 10px;
  padding: 0 8px;
}

.documentsTable td {
  padding: 20px 8px;
}

.nameCol, .uploadedCol {
  text-align: left;
}

.nameCellContainer {
  display: flex;
}

.nameCellContainer svg {
  flex-shrink: 0;
  margin-right: 8px;
}

.nameCell, .categoryCell, .uploadedCell {
  font-size: 14px;
  line-height: 20px;
}

.nameCell {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-bottom: 4px;
}

.companyCell {
  margin: 4px 0;
}

.uploadedCell {
  color: var(--foundation-gray-700);
  width: 136px;
}

.sortButton {
  background-color: var(--foundation-white) !important;
  border: 0;
  padding-left: var(--base-spacing-xs);
  padding-right: var(--base-spacing-xs);
}

.sortButton span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--base-font-font-size-md);
}

.sortButton span span {
  color: var(--intent-color-text-neutral-default);
  margin-right: var(--base-spacing-2xs);
}

.sortDropdownToggleButton {
  cursor: pointer;
  font-weight: 700;
}

@media (min-width: 550px) {
  .documentsTable th {
    color: var(--foundation-black);
    font-size: 16px;
    font-weight: 700;
    height: auto;
    padding: 0;
    text-transform: none;
  }

  .desktopTable {
    visibility: visible;
    display: table;
  }

  .mobileViewHeader {
    visibility: hidden;
    display: none;
  }

  .mobileTable {
    visibility: hidden;
    display: none;
  }

  .nameCell {
    font-size: 16px;
    font-weight: normal;
  }

  .categoryCell {
    color: var(--foundation-gray-700);
    margin-bottom: 0px;
  }
}
