.container {
  width: 740px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 0px 40px 0px;
}

.avatar {
  margin-right: 22px;
}

.nameHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.nameHeader h1 {
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.nameHeader p {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}

.editProfile button {
  padding: var(--base-spacing-xs);
}

.personalInfo {
  margin-bottom: 40px;
}

.contactInfo {
  margin-bottom: 40px;
}

.alert {
  padding: 0px 0px 40px 0px;
}

.alert a {
  color: black;
}

.notOnFile {
  color: #5E6167;
}