.formFieldContainer {
  border: 1px solid var(--foundation-gray-500);
  border-radius: 5px;
  padding: 2rem 2rem 2rem 2rem;
  margin-bottom: 2rem;
}

.formHeaderContainer {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--foundation-gray-500);
  margin-bottom: 2rem;
}

.formHeaderContainer h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.formHeaderContainer h3 + p {
  margin-top: 1em;
}

.formSubtitle {
  color: var(--foundation-black);
}

.tosCheckbox {
  padding-top: 20px;
}
