.content {
    composes: content from '/src/assets/styles/Layout.module.css';
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
}

@media (min-width: 550px) {
    .content {
        padding: var(--base-spacing-md);
    }
}
