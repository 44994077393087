.profilecard h1 {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 24px;
}

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
}

.column {
  flex: 1;
}

.column > *:not(:last-child) {
  margin-right: 40px;
}

.column .item:last-child {
  margin-bottom: 0;
}

.item {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}

.formattedAddress {
  white-space: pre;
}

strong {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
}