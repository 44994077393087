:root {
  /*
    Foundation colors are not meant to be used directly. If a component variable
    does not exist for your component, you should probably make one.
   */
  --foundation-black: #1c1d1f;
  --foundation-white: #fff;

  --foundation-blue-600: #15246d;
  --foundation-blue-400: #1e98ff;
  --foundation-blue-200: #b6e5ff;
  --foundation-blue-100: #eef7ff;

  --foundation-gray-700: #5e6167;
  --foundation-gray-500: #848890;
  --foundation-gray-400: #a9adb5;
  --foundation-gray-300: #d9dce1;
  --foundation-gray-200: #eef0f4;
  --foundation-gray-100: #f7f8fb;

  --foundation-red-600: #8e2f1a;
  --foundation-red-400: #df5a31;
  --foundation-red-200: #ff9f82;

  --foundation-yellow-600: #9e6405;
  --foundation-yellow-400: #f3b715;
  --foundation-yellow-200: #ffdb7b;

  --foundation-green-600: #017a4e;
  --foundation-green-400: #00a66c;
  --foundation-green-200: #65c9a3;

  --foundation-purple-600: #452c6c;
  --foundation-purple-400: #8a73e5;
  --foundation-purple-200: #baaaf9;
}
