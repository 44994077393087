.title {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.content {
  composes: content from '/src/assets/styles/Layout.module.css';
}

.pageHeader {
  padding-bottom: var(--base-spacing-md);
  padding-top: var(--base-spacing-md);
}

.pageHeader h1 {
  font-size: var(--base-font-font-size-xl);
  font-weight: bold;
}

@media (min-width: 700px) {
  .container {
    display: grid;
    grid-template-columns: 200px auto;
    column-gap: 1em;
  }
}
