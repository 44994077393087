.FilterableSelectInput.selection-dropdown-tree-view .level-1 {
  margin-left: 20px;
}

.FilterableSelectInput.selection-dropdown-tree-view .level-2 {
  margin-left: 40px;
}

.FilterableSelectInput.selection-dropdown-tree-view .level-3 {
  margin-left: 60px;
}

.FilterableSelectInput.selection-dropdown-tree-view .level-4 {
  margin-left: 80px;
}

.FilterableSelectInput.selection-dropdown-tree-view .level-5 {
  margin-left: 100px;
}

.FilterableSelectInput.selection-dropdown-tree-view .bold {
  font-weight: bold;
}