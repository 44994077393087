.Form {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.Form * {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.Form.spacing-medium .FormField + .FormField,
.Form.spacing-medium .FormField + .FieldGroup,
.Form.spacing-medium .FormField + .Fieldset,
.Form.spacing-medium .FieldGroup + .FormField,
.Form.spacing-medium .FieldGroup + .FieldGroup,
.Form.spacing-medium .FieldGroup + .Fieldset,
.Form.spacing-medium .Fieldset + .FormField,
.Form.spacing-medium .Fieldset + .FieldGroup,
.Form.spacing-medium .Fieldset + .Fieldset {
  margin-top: 20px;
}

.Form.spacing-medium .ActionFooter {
  margin-top: 20px;
}

.Form.spacing-large .FormField + .FormField,
.Form.spacing-large .FormField + .FieldGroup,
.Form.spacing-large .FormField + .Fieldset,
.Form.spacing-large .FieldGroup + .FormField,
.Form.spacing-large .FieldGroup + .FieldGroup,
.Form.spacing-large .FieldGroup + .Fieldset,
.Form.spacing-large .Fieldset + .FormField,
.Form.spacing-large .Fieldset + .FieldGroup,
.Form.spacing-large .Fieldset + .Fieldset {
  margin-top: 40px;
}

.Form.spacing-large .ActionFooter {
  margin-top: 40px;
}
