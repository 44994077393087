.toast {
  border-radius: var(--base-border-radius-lg);
  display: flex;
  justify-content: space-between;
  margin-top: var(--base-spacing-xs);
  padding: var(--base-spacing-md) 12px var(--base-spacing-md) var(--base-spacing-lg);
  animation: slide-in .5s;
}

.success {
  background-color: var(--intent-color-background-success-subtle);
}

.error {
  background-color: var(--intent-color-background-danger-subtle);
}

.info {
  background-color: var(--intent-color-background-info-subtle);
}

.content {
  line-height: 1.5;
}

.buttonStyle {
  flex: none;
  flex-grow: 0;
  background: none;
  border: none;
  border-radius: var(--base-border-radius-md);
  cursor: pointer;
  margin-left: var(--base-spacing-lg);
  width: var(--base-sizing-3xl);
}

.buttonStyle:focus-visible {
  outline: var(--intent-border-width-focus-ring) solid
    var(--base-color-blue-500);
  outline-offset: var(--base-spacing-2xs);
  box-shadow: 0 0 0 4px var(--base-color-white);
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
