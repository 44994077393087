.CheckboxInput {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  width: 100%;
  position: relative;
}

.CheckboxInput.disabled {
  cursor: not-allowed;
}

.CheckboxInput * {
  box-sizing: inherit;
}

.CheckboxInput > input {
  display: none;
}

.CheckboxInput > input ~ svg {
  color: transparent;
  border-width: 3px;
  
}

.CheckboxInput > input:checked ~ svg {
  border: 0;
  background-color: #1e98ff;
  color: white;
}

.keyboard-mode .CheckboxInput > input:focus ~ svg {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #1e98ff;
 /* gap */
 /* outline */
}

.CheckboxInput > input:disabled ~ svg {
  color: #eef0f4;
  background-color: #eef0f4;
}

.CheckboxInput > input:disabled:checked ~ svg {
  color: #d9dce1;
  border: 1px solid #d9dce1;
}

.CheckboxInput > input:disabled ~ .checkbox-label {
  color: #a9adb5;
  cursor: not-allowed;
}

.CheckboxInput > svg {
  flex: none;
  width: 20px;
  height: 20px;
  color: #fff;
  background-color: #fff;
  border: 1px solid #d9dce1;
  border-radius: 4px;
  position: relative;
}

.CheckboxInput > .checkbox-label {
  flex: auto;
  margin-left: 10px;
  font-family: "Oately Regular", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}

.CheckboxInput.error > input ~ svg {
  border-color: #df5a31;
}

.CheckboxInput.indeterminate svg {
  border: 0;
  background-color: #1e98ff;
}

.CheckboxInput.indeterminate .checkbox-label {
  font-weight: bold;
}

.CheckboxInput.indeterminate > input:disabled ~ svg {
  color: #d9dce1;
  border: 1px solid #d9dce1;
}
