.exportButtonContainer {
  margin-bottom: 6px;
}

.csvDownloaderButton {
  height: 36px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.datePickersContainer {
  display: flex;
  gap: var(--base-spacing-md);
  margin-top: var(--base-spacing-xl);
  flex-wrap: wrap;
  /* Below is to force <Dialog /> to stretch to its max-width */
  width: 100rem;
}

.datePickersContainer > div {
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing-xs);
}

.datePickersContainer label {
  color: var(--foundation-black);
  font-weight: var(--base-font-font-weight-bold);
  text-transform: uppercase;
  font-size: 12px;
}

.datePickersContainer input[type='date'] {
  padding: var(--base-spacing-sm);
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid var(--foundation-gray-500);
  width: 200px;
  font: inherit;
}

.datePickersContainer input[type='date'].error {
  border-color: var(--foundation-red-400);;
}

.datePickersContainer .datePickersError {
  flex-basis: 100%;
  color: var(--foundation-red-400);
}
