.FieldGroup {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);

  width: 460px
}

.FieldGroup>.FormField {
  margin-top: 0 !important
}

.FieldGroup>.FormField:not(:first-child) {
  margin-left: 10px
}

.FieldGroup>.FormField:not(:last-child) {
  margin-right: 10px
}
