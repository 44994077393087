.DownshiftWrapper * {
  box-sizing: border-box;
}

.DownshiftWrapper .dropdown-container {
  position: relative;
  width: 100%;
}

.DownshiftWrapper .top-border {
  border-top: 1px solid #a9adb5;
  margin-top: -1px;
}

.DownshiftWrapper .dw-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #1e98ff;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(28, 29, 31, 0.5);
  z-index: 1;
}

.DownshiftWrapper .dw-dropdown-menu ul {
  max-height: 290px;
  list-style: none;
  overflow-y: scroll;
  margin: 10px;
  padding-inline-start: inherit;
}