.header {
    composes: header from '/src/assets/styles/TextStyles.module.css'
  }

  .subheader {
    composes: subheader from '/src/assets/styles/TextStyles.module.css'
  }

  .secondaryHeader {
    composes: secondaryHeader from '/src/assets/styles/TextStyles.module.css'
  }

  .alreadyMember {
    margin-top: 40px;
  }

  .link {
    composes: link from '/src/assets/styles/TextStyles.module.css'
  }
