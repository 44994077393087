.menuItem {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 12px;
    text-decoration: none;
}

.menuItem:hover {
    background-color: var(--foundation-blue-100);
    cursor: pointer;
    text-decoration: none;
    transition: 0.25s;
}
