.FormField > .label-container > label {
  font-family: "Oately Regular", Helvetica, Arial, sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--foundation-black);
}
.FormField > .label-container > label > .optional-tag {
  color: var(--foundation-gray-400);
  padding-top: 10px;
}
.FormField > .field-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}
.FormField > .field-container > .input-container {
  max-width: 440px;
  width: 100%;
}
.FormField > .field-container > .input-container > :first-child {
  width: 100%;
}
.FormField > .field-container > .input-container > .error-message {
  color: var(--foundation-red-400);
  margin-top: 10px;
}
.FormField
  > .field-container
  > .input-container
  > .error-message::first-letter {
  text-transform: capitalize;
}
.FormField > .field-container > .description {
  max-width: 440px;
  width: 100%;
  margin: 0px;
  min-height: 40px;
  color: var(--foundation-gray-500);
}
.FormField.error input[type="text"],
.FormField.error textarea,
.FormField.error .dw-dropdown-menu,
.FormField.error .DecoratedTextInput > *,
.FormField.error .DecoratedTextInput > *:hover {
  border-color: var(--foundation-red-400);
}
.FormField.error input[type="radio"] + label {
  -webkit-box-shadow: inset 0 0 0 1px var(--foundation-red-400);
  box-shadow: inset 0 0 0 1px var(--foundation-red-400);
}
.Form.layout-vertical .FormField:not(.layout-horizontal) > .label-container,
.FormField.layout-vertical > .label-container {
  margin-bottom: 4px;
}
.Form.layout-vertical .FormField:not(.layout-horizontal) .optional-tag,
.FormField.layout-vertical .optional-tag {
  margin-left: 10px;
}
.Form.layout-horizontal .FormField:not(.layout-vertical),
.FormField.layout-horizontal {
  display: grid;
  grid-template-columns: 160px auto;
}
@media (max-width: 500px) {
  .Form.layout-horizontal .FormField:not(.layout-vertical),
  .FormField.layout-horizontal {
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: 10px;
  }
}
.Form.layout-horizontal .FormField:not(.layout-vertical) > .label-container,
.FormField.layout-horizontal > .label-container {
  width: 160px;
  padding-top: 11px;
}
.Form.layout-horizontal .FormField:not(.layout-vertical) .optional-tag,
.FormField.layout-horizontal .optional-tag {
  display: block;
}
.Form.dynamic-layout .FormField > .field-container,
.Form.dynamic-layout .FormField > .label-container {
  width: 100%;
}
.Form.dynamic-layout .FormField > .field-container > .input-container {
  width: auto;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.Form.dynamic-layout .FormField > .field-container > .description {
  width: auto;
}
