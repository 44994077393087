.legalLink {
  composes: legalLink from '/src/assets/styles/TextStyles.module.css';
}

.currencyComprehensionPrompt {
  margin: 16px 0 32px 0;
  padding: 20px;
  background-color: var(--foundation-gray-100);
  max-width: 440px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  border-radius: 4px;
}

.currencyComprehensionPrompt > label {
  font-family: "Oately Regular", Helvetica, Arial, sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--foundation-black);
}

.currencyComprehensionPrompt > span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.updateButtonContainer {
  padding-top: 48px;
}

.updateButtonContainer > button:first-of-type {
  margin-right: 20px;
}

.footer {
  margin-top: 20px;
}
