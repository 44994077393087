.Notice {
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;

  overflow: hidden;
  display: flex;
  align-items: center;
  z-index:1;
  max-width: 960px;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  position: absolute;
  top: 220px;
  white-space: nowrap;
}

.Notice.display-relative {
  position: relative;
  top: 0;
  margin-top: 20px;
  white-space: initial;
  gap: 20px;
}

.Notice.display-absolute {
  white-space: initial;
  gap: 20px;
}

.Notice>.text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

h4 {
  margin: 5px 0px 5px 0px ;
}

p{
  margin: 0px;
}

.Notice.success {
  background: #65c9a3;
  border: 1px #017a4e solid;
}

.Notice.info {
  background:#ffdb7b;
  border: 1px #f3b715 solid;

}

.Notice.warning {
  background:#ff9f82;
  border: 1px #df5a31 solid;
}

.Notice.header {
  margin-bottom: 10px;
}

.status {
  flex: none;
  text-transform: uppercase;
  font: bold 10px/1 "Oately Regular", Helvetica, Arial, sans-serif;
  height: 18px;
  padding: 4px 10px 0px;
  color: #fff;
  border-radius: 17px;
  -webkit-font-smoothing: antialiased;
}

.status.pending {
  background-color: #9e6405;
  width: 70px;
  text-align: center;
}

.status.unsuccessful {
  background-color: #8e2f1a;
  width: 110px;
  text-align: center;
}

.button {
  background: #1c1d1f;
  border: none;
}

.link {
  color: var(--foundation-black);
}
