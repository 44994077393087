.container {
    width: 740px;
    margin: 0 auto 40px;
  }

.pageHeader {
    padding-bottom: var(--base-spacing-md);
    padding-top: var(--base-spacing-md);
}

.pageHeader h1 {
    font-size: var(--base-font-font-size-md);
}