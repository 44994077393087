@font-face {
  font-family: "Oately Regular";
  src: url("assets/fonts/Oately-Regular.woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Oately Regular";
  src: url("assets/fonts/Oately-Bold.woff2");
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Oately Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

html, body {
  box-sizing: border-box;
}

body * {
  box-sizing: inherit;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}
