:root {
  --button-background--primary: var(--primary);
  --button-background--secondary: var(--foundation-black);
  --button-background--accent: var(--foundation-yellow-400);
  --button-background--ghost: var(--foundation-white);
  --button-background--disabled: var(--disabled);
  --button-background--danger: var(--foundation-red-400);
  --button-background--success: var(--foundation-green-400);

  --feedback-background--danger: var(--foundation-red-200);
  --feedback-background--warning: var(--foundation-yellow-200);
  --feedback-background--success: var(--foundation-green-200);
  --feedback-background--info: var(--foundation-purple-200);
  --feedback-background--special-notice: var(--foundation-yellow-600);
}