.FieldGroup {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  grid-column-gap: 20px;
  max-width: 440px;
  width: 100%;
}

.FieldGroup > div {
  margin-bottom: 0;
}

.FieldGroup input {
  width: 100%;
}
