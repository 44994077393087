.container {
  margin-bottom: 20px;
  max-width: 440px;
}

.container:not(.group) input {
  width: 440px;
}

.container:last-child {
  margin-bottom: 0;
}

.container li:last-child {
  padding-bottom: 0;
}
