.container {
  width: 740px;
  margin: 0 auto;
}

.alert {
  padding: 0px 0px 40px 0px;
}

.alert a {
  color: black;
}
