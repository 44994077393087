.Button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-family: "Oately Regular", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 1.4;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  padding: 11px 20px;
  position: relative;
  width: auto;
  background: var(--button-background--primary);
  border: 1px solid var(--button-background--primary);
  color: #fff;
}

.Button:hover {
  background-color: #75c0ff;
  border-color: #75c0ff;
  color: #fff;
}

.Button.loading,
.Button:active {
  background-color: #007fea;
}

.Button:not(.loading).disabled,
.Button:not(.loading) :disabled {
  background-color: #d9dce1;
  border-color: #d9dce1;
  pointer-events: none;
  cursor: not-allowed;
}

.Button:not(.text) {
  outline: 0 none;
}

.keyboard-mode .Button:not(.text):focus::before {
  border-radius: 3px;
  bottom: 0;
  content: " ";
  border: 2px solid #fff;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.keyboard-mode .Button:not(.text):focus.secondary::before {
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  border-width: 1px;
  border-radius: 2px;
}

.Button.text {
  background-color: transparent;
  border-color: transparent;
  border: 0 none;
  color: var(--button-background--primary);
  font-family: inherit;
  font-weight: normal;
  font-size: inherit;
  letter-spacing: 0;
  text-transform: none;
  text-align: left;
  display: inline-block;
  padding: 0;
  outline: 0;
}

.Button.text.green:disabled,
.Button.text.red:disabled,
.Button.text.disabled,
.Button.text:disabled {
  background-color: transparent;
  color: #d9dce1;
}

.keyboard-mode .Button.text:focus {
  outline: auto;
}

.Button.red {
  background-color: #df5a31;
  border-color: #df5a31;
}

.Button.red:hover {
  background-color: #e57b5a;
  border-color: #e57b5a;
}

.Button.red.loading,
.Button.red:active {
  background-color: #bf441e;
}

.Button.green {
  background-color: #00a66c;
  border-color: #00a66c;
}

.Button.green:hover {
  background-color: #33b889;
  border-color: #33b889;
}

.Button.green.loading,
.Button.green:active {
  background-color: #00734b;
}

.Button.frameless,
.Button.secondary {
  background-color: transparent;
}

.Button.frameless.blue,
.Button.secondary.blue {
  color: var(--button-background--primary);
}

.Button.frameless.blue:hover,
.Button.secondary.blue:hover {
  background-color: #d2eaff;
}

.Button.frameless.blue.loading .Spinner > span,
.Button.secondary.blue.loading .Spinner > span {
  background-color: var(--button-background--primary);
}

.Button.frameless.blue.loading,
.Button.frameless.blue:active,
.Button.secondary.blue.loading,
.Button.secondary.blue:active {
  background-color: #a5d6ff;
}

.keyboard-mode .Button.frameless.blue:focus::before,
.keyboard-mode .Button.secondary.blue:focus::before {
  border-color: var(--button-background--primary);
}

.Button.frameless.red,
.Button.secondary.red {
  border-color: #df5a31;
  background-color: #fcefea;
  color: #df5a31;
}

.Button.frameless.red:hover,
.Button.secondary.red:hover {
  background-color: #f4c5b7;
}

.Button.frameless.red.loading .Spinner > span,
.Button.secondary.red.loading .Spinner > span {
  background-color: #df5a31;
}

.Button.frameless.red.loading,
.Button.frameless.red:active,
.Button.secondary.red.loading,
.Button.secondary.red:active {
  background-color: #fdf7f5;
}

.keyboard-mode .Button.frameless.red:focus::before,
.keyboard-mode .Button.secondary.red:focus::before {
  border-color: #df5a31;
}

.Button.frameless.green,
.Button.secondary.green {
  border-color: #00a66c;
  background-color: #e6f6f0;
  color: #00a66c;
}

.Button.frameless.green:hover,
.Button.secondary.green:hover {
  background-color: #a6e0cc;
}

.Button.frameless.green.loading .Spinner > span,
.Button.secondary.green.loading .Spinner > span {
  background-color: #00a66c;
}

.Button.frameless.green.loading,
.Button.frameless.green:active,
.Button.secondary.green.loading,
.Button.secondary.green:active {
  background-color: #f2fbf8;
}

.keyboard-mode .Button.frameless.green.focused::before,
.keyboard-mode .Button.frameless.green:focus::before,
.keyboard-mode .Button.secondary.green.focused::before,
.keyboard-mode .Button.secondary.green:focus::before {
  border-color: #00a66c;
}

.Button.frameless.gray,
.Button.secondary.gray {
  border-color: #848890;
  color: #848890;
}

.Button.frameless.gray:hover,
.Button.secondary.gray:hover {
  background-color: #d4d5d8;
}

.Button.frameless.gray.loading .Spinner > span,
.Button.secondary.gray.loading .Spinner > span {
  background-color: #848890;
}

.Button.frameless.gray.loading,
.Button.frameless.gray:active,
.Button.secondary.gray.loading,
.Button.secondary.gray:active {
  background-color: #f9f9f9;
}

.keyboard-mode .Button.frameless.gray.focused::before,
.keyboard-mode .Button.frameless.gray:focus::before,
.keyboard-mode .Button.secondary.gray.focused::before,
.keyboard-mode .Button.secondary.gray:focus::before {
  border-color: #848890;
}

.Button.frameless:not(.loading).green:disabled,
.Button.frameless:not(.loading).red:disabled,
.Button.frameless:not(.loading).disabled,
.Button.frameless:not(.loading):disabled,
.Button.secondary:not(.loading).green:disabled,
.Button.secondary:not(.loading).red:disabled,
.Button.secondary:not(.loading).disabled,
.Button.secondary:not(.loading):disabled {
  background-color: transparent;
  border-color: #ccd6da;
  color: #ccd6da;
}

.Button.frameless:not(.loading).green:disabled:hover,
.Button.frameless:not(.loading).red:disabled:hover,
.Button.frameless:not(.loading).disabled:hover,
.Button.frameless:not(.loading):disabled:hover,
.Button.secondary:not(.loading).green:disabled:hover,
.Button.secondary:not(.loading).red:disabled:hover,
.Button.secondary:not(.loading).disabled:hover,
.Button.secondary:not(.loading):disabled:hover {
  background-color: transparent;
}

.Button.secondary {
  background-color: #fff;
}

.Button.large {
  padding: 21px 40px;
}

.Button.small {
  font-weight: normal;
  font-size: 9px;
  padding: 3px 6px 2px;
}

.Button.loading {
  cursor: wait;
}

.Button.loading > .Spinner {
  visibility: visible;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.Button.loading > :not(.Spinner) {
  visibility: hidden;
}

.Button.frameless:not(.loading).disabled.green,
.Button.frameless:not(.loading).disabled.red,
.Button.frameless:not(.loading).disabled.blue,
.Button.frameless:not(.loading).disabled.gray,
.Button.frameless:not(.loading):disabled.green,
.Button.frameless:not(.loading):disabled.red,
.Button.frameless:not(.loading):disabled.blue,
.Button.frameless:not(.loading):disabled.gray,
.Button.frameless.green,
.Button.frameless.red,
.Button.frameless.blue,
.Button.frameless.gray {
  background-color: transparent;
  border-color: transparent;
}

.Button.frameless:not(.loading).disabled.blue,
.Button.frameless:not(.loading):disabled.blue,
.Button.frameless.blue {
  color: var(--button-background--primary);
}

.Button.frameless:not(.loading).disabled.red,
.Button.frameless:not(.loading):disabled.red,
.Button.frameless.red {
  color: #df5a31;
}

.Button.frameless:not(.loading).disabled.green,
.Button.frameless:not(.loading):disabled.green,
.Button.frameless.green {
  color: #00a66c;
}

.Button.frameless:not(.loading).disabled.gray,
.Button.frameless:not(.loading):disabled.gray,
.Button.frameless.gray {
  color: #848890;
}

.Button.frameless:not(.loading).disabled.blue,
.Button.frameless:not(.loading).disabled.red,
.Button.frameless:not(.loading).disabled.green,
.Button.frameless:not(.loading).disabled.gray,
.Button.frameless:not(.loading):disabled.blue,
.Button.frameless:not(.loading):disabled.red,
.Button.frameless:not(.loading):disabled.green,
.Button.frameless:not(.loading):disabled.gray {
  color: #ccd6da;
}

.Button__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.text .Button__content {
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}

.Button__content .hidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: polygon(0 0, 0 0, 0 0);
  -webkit-clip-path: polygon(0 0, 0 0, 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.Button__icon {
  width: 20px;
  height: 20px;
}

.Button__icon--before-text {
  margin-right: 5px;
}

.Button__icon--after-text {
  margin-left: 5px;
}
