.main {
  margin: 20px 40px;
}

@media (min-width: 700px) {
  .main {
    margin: 20px 150px;
  }
}

.header {
  width: 100%;
  text-align: center;
}

.contentSection {
  padding-bottom: 20px;
}

.mailingAddress {
  text-align: center;
}

li {
  padding-bottom: 20px;
}
