.container {
  composes: container from '/src/assets/styles/InputStyles.module.css'
}

.container:focus-within {
  border-color: #1e98ff;
}

.passwordInput {
  width: 100%;
  border: unset !important; /* !important added because of https://justworks.atlassian.net/browse/INTL-131 */
}

.visibilityToggle {
  color: var(--foundation-blue-400);
  padding: 0;
  height: 20px;
  width: 20px;
}

.visibilityToggle:hover {
  cursor: pointer;
  border-radius: 4px;
}

.eyeIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
  height: 100%;
}

.eyeIcon:focus {
  border: 2px solid var(--foundation-blue-400);
  border-radius: 4px;
}

.eyeIcon {
  border: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
