.text {
  font-size: 14px;
  line-height: 20px;
}

.valid {
  color: var(--foundation-green-400);
}

.invalid {
  color: var(--foundation-gray-500);
}

.icon {
  width: 14px;
  height: 14px;
}
