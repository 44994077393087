.legalLink {
  composes: legalLink from '/src/assets/styles/TextStyles.module.css';
}

.buttonsContainer{
  margin-top: 1.5rem;
  width: 330px;
  display: flex;
  justify-content: space-between;
}

.completedPersona{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.spinner > span{
  background-color: var(--foundation-blue-200);
}

.cardTitle {
  margin-top: 0;
}
