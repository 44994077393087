.step {
  display: flex;
  align-items: center;
  height: 55px;
}

.step-circle {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.step-circle.completed {
  background-color: var(--foundation-green-400);
}

.step-circle.current {
  background-color: var(--foundation-blue-200);
}

.step-circle.to-do {
  background-color: var(--foundation-gray-200);
}

.step-check {
  height: 1rem;
  width: 1rem;
  color: white;
}

.step-num {
  margin: 0;
  padding-bottom: 1px;
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
}

.step-title {
  padding-left: 0.5rem;
}

.step-title.current {
  font-weight: bold;
}

.title-to-do {
  color: var(--foundation-gray-500);
}

.step-stroke {
  width: 1px;
  background-color: var(--foundation-gray-400);
  height: 40px;
  margin: -20px 0 -20px 9px;
}

.active-button:hover {
  text-decoration: underline;
  cursor: pointer;
}