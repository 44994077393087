.DefaultListItem {
  font-size: 14px;
  padding: 10px;
  min-height: 40px;
  border-radius: 4px;
  margin: 0;
}

.DefaultListItem.highlighted {
  background-color: #eef7ff;
  cursor: default;
}

.DefaultListItem.selected {
  font-weight: bold;
}