.floating {
  position: fixed;
  bottom: 24px;
}

.tabBar {
  align-items: center;
  background-color: var(--foundation-gray-700);
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 4px;
  position: relative;
}

.floating > .tabBar {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.slider {
  background-color: var(--foundation-black);
  border-radius: 100px;
  transition: all;
  transition-duration: 0.3s;
  position: absolute;
}

.tab {
  cursor: pointer;
  display: flex;
  padding: 4px 12px;
  z-index: 2;
}

.tabName {
  display: flex;
  align-items: center;
  color: var(--foundation-white);
}
