.profileCard {
  margin-bottom: var(--base-spacing-xl);
}

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--base-spacing-5xl);
}

.column {
  flex: 1;

  &:last-child {
    flex: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.column .item:last-child {
  margin-bottom: 0;
}


.avatarPreviewContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: var(--base-spacing-md);
}
