.paymentsTable {
  padding-top: var(--base-spacing-3xl);
  width: 100%;
}

.tableTitle {
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px;
}

.desktopTable {
  visibility: hidden;
  display: none;
}

.mobileTable {
  visibility: visible;
  display: table;
}

@media (min-width: 600px) {
  .desktopTable {
    visibility: visible;
    display: table;
  }

  .mobileTable {
    visibility: hidden;
    display: none;
  }
}

.paymentsTable table {
  border-collapse: separate;
  border-spacing: 0 var(--base-spacing-xs);
  width: 100%;
  margin: 0 auto;
}

.paymentsTable tr {
  background-color: var(--base-color-gray-100);
}

.paymentsTable tr:last-child {
  margin-bottom: 0;
}

tr td:first-child {
  border-top-left-radius: var(--base-spacing-xs);
  border-bottom-left-radius: var(--base-spacing-xs);
}

tr td:last-child {
  border-top-right-radius: var(--base-spacing-xs);
  border-bottom-right-radius: var(--base-spacing-xs);
}

.paymentsTable th {
  color: var(--foundation-gray-700);
  font-weight: var(--base-font-font-weight-bold);
  text-transform: uppercase;
  font-size: 12px;
}

.paymentsTable td, .paymentsTable th {
  padding: 20px 16px;
  border-style: none;
}

.dateCol, .companyCol {
  text-align: left;
}

.paymentsTable td.dateCol, .paymentsTable div.dateCol {
  font-weight: var(--base-font-font-weight-bold);
}

.paymentsTable td.usdAmountCol {
  color: var(--foundation-gray-700);
}

.paymentsTable td.localAmountCol, .paymentsTable div.localAmountCol {
  font-weight: var(--base-font-font-weight-bold);
}

.paymentsTable div.usdAmountCol, .paymentsTable div.companyCol {
  color: var(--foundation-gray-700);
}

.usdAmountCol, .localAmountCol {
  text-align: right;
}

.currencyContainer {
  display: inline-flex;
  column-gap: 5px;
  align-items: flex-end;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.currencyLabel {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.spinner > span {
  background-color: var(--foundation-black);
}
