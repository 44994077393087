/* single-column viewports */

.header {
  background-color: var(--foundation-black);
  z-index: 1000;
  position: sticky;
  padding: 1.5rem;
  width: 100%;
  top: 0;
  color: var(--foundation-white);
}

.header nav {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

/* Mobile First: Reordering nav elements so the account dropdown is inline with the logo on mobile devices */
.logoLink { order: 1; }
.navPrimary {
  margin-right: 24px;
  font-size: 14px;
}
.navSecondary {
  font-size: 14px;
}

.sliderTabBarContainer {
  display: none;
}

.navRight {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 80%;
  order: 3;
}

.navPrimary a {
  display: block;
}

.navSecondary a {
  display: block;
}

.logo {
  height: 14px;
  display: inline-flex;
  margin-right: 24px;
}

.help {
  display: flex;
}

.helpButton {
  display: flex;
}

.helpIcon {
  display: block;
  color: var(--foundation-gray-500);
  margin-right: 4px;
}

.help:hover, .helpButton {
  cursor: pointer;
}

.help:focus .helpIcon,
.help:focus-within .helpIcon {
  color: var(--primary);
}

.help:focus .helpContent,
.help:focus-within .helpContent {
  display: block;
}

.helpContent {
  display: none;
  width: 322px;
  border-radius: 4px;
  margin: auto;
  position: absolute;
  right: -15px;
  top: 65%;
  right: 1%;
  margin-top: 6px;
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid var(--intent-color-border-neutral-subtle, #A9ADB5);
  background: var(--intent-color-bg-neutral-subtle, #F7F8FB);
}

.helpContentHeader {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: #1C1D1F;
  padding-bottom: 8px;
}

.helpContentBody {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #1C1D1F;
}

@media (min-width: 500px) {
  .header nav {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .logo {
    height: 14px;
  }

  .logoLink {
    height: 1.25rem;
    order: 1;
  }

  .navRight {
    width: auto;
    flex-grow: 0;
    align-items: flex-start;
    justify-content: center;
  }

  .navPrimary {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .navSecondary {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
}

.link {
  composes: link from '/src/assets/styles/TextStyles.module.css';
}

.dropdown {
  position: relative;
}

.dropdownButton {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: right;
}

.dropdown:hover, .dropdownButton {
  cursor: pointer;
}

.dropdownContent {
  display: none;
  width: 200px;
  border-radius: 5px;
  position: absolute;
  top: auto;
  right: 0;
  margin-top: 8px;
  background-color: var(--foundation-white);
  box-shadow:
    0 8px 8px rgba(21,36,109,.08),
    0 4px 4px rgba(0,0,0,.12),
    0 0 1px rgba(21,36,109,.5);
  z-index: 1;
}

.dropdownContentOption {
  padding: 12px 16px;
  text-align: left;
}

.dropdownContentLink {
  composes: link from '/src/assets/styles/TextStyles.module.css';
}

.dropdown:focus .dropdownContent,
.dropdown:focus-within .dropdownContent {
  display: block;
}

.dropdown:focus .dropdownIcon,
.dropdown:focus-within .dropdownIcon {
  color: var(--primary);
}

.dropdownIcon {
  display: block;
  margin: auto 0;
  color: var(--foundation-gray-500);
  flex-shrink: 0;
}

@media (min-width: 550px) {
  .header nav {
    display: flex;
    align-items: center;
    height: 32px;
  }

  .navRight {
    align-items: center;
  }

  .sliderTabBarContainer {
    display: flex;
    align-items: center;
    order: 2;
    margin-right: 24px;
  }

  .dropdownButton {
    align-items: center;
  }
}
