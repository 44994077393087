.paymentsTable {
  width: 100%;
  padding-top: var(--base-spacing-3xl);
}

.tableTitleContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.tableTitle {
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px;
}

.desktopTable {
  visibility: hidden;
  display: none;
}

.mobileTable {
  visibility: visible;
  display: table;
}

@media (min-width: 600px) {
  .desktopTable {
    visibility: visible;
    display: table;
  }

  .mobileTable {
    visibility: hidden;
    display: none;
  }
}

.paymentHistoryPlaceholder {
  margin-top: 60px;
  color: var(--foundation-gray-700);
  display: flex;
  flex-flow: column;
  align-items: center;
  border: 1px solid #a9adb5;
  border-radius: 4px;
  padding: 3rem 0;
  width: 100%;
  text-align: center;
}

.paymentHistoryPlaceholder img {
  color: #a9adb5;
}

.paymentHistoryPlaceholder p {
  margin: 0.35rem 0;
}

.paymentsTable table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
}


.paymentsTable thead, .paymentsTable tr {
  border-bottom: solid black 1px;
}

.paymentsTable th {
  color: var(--foundation-gray-700);
  font-weight: var(--base-font-font-weight-bold);
  text-transform: uppercase;
  font-size: 12px;
}

.paymentsTable td, .paymentsTable th {
  padding: 20px 0;
}

.dateCol, .companyCol {
  text-align: left;
}

.paymentsTable td.dateCol, .paymentsTable div.dateCol {
  font-weight: var(--base-font-font-weight-bold);
}

.paymentsTable td.usdAmountCol {
  color: var(--foundation-gray-700);
}

.paymentsTable td.localAmountCol, .paymentsTable div.localAmountCol {
  font-weight: var(--base-font-font-weight-bold);
  color: var(--foundation-green-400);
}

.paymentsTable div.usdAmountCol, .paymentsTable div.companyCol {
  color: var(--foundation-gray-700);
}

.usdAmountCol, .localAmountCol {
  text-align: right;
}

.currencyContainer {
  display: inline-flex;
  column-gap: 5px;
  align-items: flex-end;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.currencyLabel {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.spinner > span {
  background-color: var(--foundation-black);
}
