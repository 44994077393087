.Alert{
  font-family:"Oately Regular",Helvetica,Arial,sans-serif;
  font-size:14px;
  font-weight:400;
  display:none;
  color:#1c1d1f;
  width:100%;
  padding:16px 40px 13px;
  -webkit-box-sizing:border-box;
  box-sizing:border-box
}

.Alert.visible{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center
}

.Alert.success{
  background-color:#65c9a3
}

.Alert.notice{
  background:#baaaf9
}

.Alert.error{
  background:#ff9f82
}

.Alert.warning{
  background:#ffdb7b
}

.Alert>.children{
  -webkit-box-flex:1;
  -ms-flex-positive:1;
  flex-grow:1
}

.Alert>.children.left{
  text-align:left
}

.Alert>.children.center{
  text-align:center
}

.Alert>.children>a,
.Alert>.children>button{
  color:#fff;
  margin-left:10px;
  text-decoration:underline
}

.Alert>.children>a:hover,
.Alert>.children>button:hover{
  border-bottom:none;color:#f7f8fb
}

.Alert>button.dismiss{
  border:0 none;
  visibility:hidden;
  width:19px;
  height:19px;
  margin-top:-2px;
  padding:0
}

.Alert>button.dismiss.visible{
  visibility:visible;
  background-color:transparent
}

.Alert>button.dismiss.visible:hover{
  cursor:pointer
}
