.table,
.table th,
.table td {
  border: 1px solid;
  border-collapse: collapse;
  padding: 5px;
}

.content {
  composes: content from '/src/assets/styles/Layout.module.css';
}
