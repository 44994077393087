.ActionFooter {
  background-color: var(--intent-color-bg-neutral-subtle, #F7F8FB);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
}

.ActionFooter > aside {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ActionFooter > aside > * + * {
  margin-left: 20px;
}

.ActionFooter > aside + * {
  margin-left: auto;
}

.ActionFooter > * + * {
  margin-left: 20px;
}
