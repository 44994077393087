.toastList {
  position: fixed;
  width: calc(100vw - 48px);
  left: 24px;
  bottom: var(--base-spacing-xl);
  margin: auto;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (min-width: 500px) {
  .toastList {
    width: 369px;
    bottom: var(--base-spacing-xl);
    right: var(--base-spacing-2xl);
    margin-right: 0;
  }
}
