.earningsBannerContainer {
  display: flex;
  gap: var(--base-spacing-md);
  flex-direction: column;
  margin-top: var(--base-spacing-md);
}

.earningsBanner {
  background-color: var(--intent-color-background-neutral-subtle);
  color: var(--base-color-gray-900);
  padding: var(--base-spacing-lg);
  border-radius: var(--base-border-radius-lg);
  z-index: 0;
  width: 100%;
}

.currencyAmount {
  word-break: break-all;
}

@media (min-width: 600px) {
  .earningsBannerContainer {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .earningsBannerContainer:has(> .earningsBanner:nth-child(3)) { /* When the container has a 3rd card, target all cards inside it */
    .earningsBanner {
      flex: 0 1 312px;
    }
  }
  .earningsBanner {
    max-width: 360px;
  }
}

.total {
  font-size: 1.75rem;
  font-variant-numeric: tabular-nums;
  padding: var(--base-spacing-2xs) 0 0;
  display: flex;
  align-items: flex-end;
}

.currencyLabel {
  padding-left: 10px;
  font-size: 1rem;
  text-transform: uppercase;
  color: var(--base-color-gray-700);
  font-weight: var(--base-font-font-weight-bold);
  white-space: nowrap;
}

.bannerSubtitle {
  font-size: 14px;
  padding-top: 10px;
}

.spinner {
  justify-content: start;

  span {
    background-color: var(--base-color-gray-900);
  }
}
