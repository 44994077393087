.container {
  display: inline-flex;

  border: 1px solid var(--foundation-gray-300);
  border-radius: 4px;
}

.container:hover {
  border-color: var(--foundation-gray-400);
}
