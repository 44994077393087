.container {
  background-color: var(--foundation-blue-100);
  min-height: 100vh;
  display: grid;
}

.logo {
  height: 24px;
}

.title {
  display: none;
  font-size: 40px;
  line-height: 1;
  font-weight: bold;
}

.shortTitle {
  line-height: 2.5;
}

.header {
  padding: 24px;
}

.main {
  background-color: var(--foundation-white);
  border-top: 1px solid var(--foundation-gray-300);
  border-bottom: 1px solid var(--foundation-gray-300);
  padding: 40px;
  position: relative;
}

.footer {
  padding: 24px;
}

.smallText {
  font-size: 14px;
  color: var(--foundation-gray-700);
}

.highlightedText {
  background-color: var(--foundation-blue-200);
}

@media (min-width: 700px) {
  .container {
    grid-template-columns: 2fr 3fr;
    grid-template-areas:
      "a b"
      "d e";
  }

  .shortTitle {
    display: none;
  }

  .logo {
    height: 15px;
  }

  .header {
    grid-area: a;
  }

  .main {
    grid-area: b;
    grid-row: b / e;
    display: grid;
    justify-content: center;

    border-radius: 8px;
    border: 1px solid var(--foundation-gray-200);
    box-shadow:
      0 8px 8px rgba(21,36,109,.08),
      0 4px 4px rgba(0,0,0,.12),
      0 0 1px rgba(21,36,109,.5);

    margin: 24px;
  }

  .mainContent {
    max-width: 500px;
  }

  .alertContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .footer {
    grid-area: d;
    align-self: end;
  }

  .title {
    display: block;
  }
}
